import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  activeItemMenu: number = 1
  constructor(
    public sidebarService: SidebarService,
  ) { }

  ngOnInit(): void {
  }

  changeActiveItemMenu(menu: number){

  }

}
