import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  active: 'principal' | 'settings' = 'principal'; 
  constructor() { }
}
