import { Injectable, NgZone } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone,
  ) { }

  public openSnackbar(message: string, htmlClass="green-snackbar", action = '', duration = 3000) {
    this.zone.run(() => {
        this.snackBar.open(message, action, { 
          duration,
          panelClass: [htmlClass],
          verticalPosition: "top",
          horizontalPosition: "center"});

    });
}

catchHttpError(err: any): void{
  const message = err.error ? err.error.message : '';
  this.openSnackbar(message, "red-snackbar");
}

}
