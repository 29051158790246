  <div fxFlexLayout='row' fxFlexFill>
    <div class="sidebar" fxFlex='15'>
      <app-sidebar></app-sidebar>
    </div>
    <div fxFlex='80'>
      <div class="main-content">
        <app-settings [ngStyle]="{display: this.sidebarService.active =='settings' ? 'block' : 'none'}"></app-settings>
        <app-principal [ngStyle]="{display: this.sidebarService.active =='principal' ? 'block' : 'none'}"></app-principal>
        <!-- <router-outlet></router-outlet> -->
      </div>
    </div>
  </div>