<section class="settings">
    <div class="header">
        <div class='title'>Configuracion</div>
    </div>

    <div class='settings-container database-settings'>
        <div class='title'>Conexion para base de datos</div>
        <div class='fields' fxFlexLayout="row warp" fxLayoutGap='25px' [formGroup]='connectionFormGroup'>
            <div fxFlex='28'>
                <mat-form-field appearance="outline" class="w-100-p">
                    <mat-label>Host</mat-label>
                    <input formControlName='host' matInput>
                </mat-form-field>
            </div>
            <div fxFlex='28'>
                <mat-form-field appearance="outline" class="w-100-p">
                    <mat-label>Nombre de base de datos</mat-label>
                    <input formControlName='database' matInput>
                </mat-form-field>
            </div>
            <div fxFlex='26' class="d-flex justify-content-space-between">
                    <div style="width:45%">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>Usuario</mat-label>
                            <input formControlName='user' matInput>
                        </mat-form-field>
                    </div>

                    <div style="width:45%">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>Clave</mat-label>
                            <input type="password" formControlName='password' matInput>
                        </mat-form-field>
                    </div>
            </div>
            <div fxFlex='16'>
                <div class="save-btn">
                    <button  class='w-100-p' (click)='setConnectionData()' mat-raised-button>Guardar</button>
                </div>
            </div>
        </div>
    </div>


    <div class='settings-container terminals-settings'>
        <div class='title'>Terminales Incluidos para importar datos</div>
        <div class='fields' fxFlexLayout="row warp" fxLayoutGap='25px' [formGroup]='credentialsFormGroup'>
            <div fxFlex='33'>
                <mat-form-field appearance="outline" class="w-100-p">
                    <mat-label>ID de acceso</mat-label>
                    <input formControlName='accessID' matInput>
                </mat-form-field>
            </div>
            <div fxFlex='33'>
                <mat-form-field appearance="outline" class="w-100-p">
                    <mat-label>Contraseña</mat-label>
                    <input type="password" formControlName='password' matInput>
                </mat-form-field>
            </div>
            <div fxFlex='33'>
                <div class="save-btn d-flex justify-content-space-between">
                    <button class='w-100-p' (click)='updateCredential()' mat-raised-button>Guardar</button>
                </div>
            </div>
        </div>
    </div>
</section>