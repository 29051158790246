import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from './sidebar/sidebar.module';
import { SidebarComponent } from './sidebar/sidebar.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SidebarModule
  ],
  exports: [SidebarComponent]
})
export class GeneralModule { }
