<section class="principal">
    <div class="header">
        <div class='title'>Informe de aguas subterraneas</div>
    </div>
    <div class='informe-principal'>
        <!-- <div fxLayout='row'>
            <div fxFlex="33">Hola</div>
            <div fxFlex="33">Hola</div>
            <div fxFlex="33">Hola</div>
        </div> -->
    </div>

    <div class='principal-container funcion-princpal'>
        <div FxLayout='row'>
            <div fxFlex="50" class='title'>
                <p>
                    Monitorieo de actividad
                </p>
            </div>
            <div fxFlex="30">

            </div>
            <div fxFlex="15" class='t-align-right'>
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>Intervalo en segundos</mat-label>
                        <input type='number' [formControl]='timer' matInput>
                    </mat-form-field>
            </div>
            <div fxFlex="15" class='t-align-right'>
                <button (click)='startInformeCicle()' *ngIf='!informeCicle' class="start-function"mat-button>Iniciar ciclo de informes</button>
                <button (click)='stopInformeCicle()' *ngIf='informeCicle' class="stop-function"mat-button>Detener ciclo</button>
            </div>
        </div>
        <div class='principal-container live-informe'>
            <div FxLayout='row'>
                <div fxFlex="50" class='title'>
                    <p>
                        {{monitoreoActividad}}
                    </p>
                </div>
                <div fxFlex="50" class='t-align-right'>
                    <mat-spinner *ngIf='informeCicle' class='ml-auto' [diameter]="30"></mat-spinner>
                </div>
            </div>
            <mat-divider></mat-divider>

            <div class='live-table'>
                <table class='w-100-p'>
                    <thead>
                        <td class='w-15-p'>Status</td>
                        <td class='w-15-p'>Hora Intento</td>
                        <td class='w-15-p'>Codigo Terminal</td>                        
                        <td class='w-15-p'>Hora Respuesta recibida</td>
                        <td class='w-15-p'>Respuesta del servidor</td>
                    </thead>
                    <tbody #scrollMe>
                        <tr *ngFor="let row of informeActividad">
                            <td class='w-15-p'>
                                <mat-icon *ngIf='row.error' class='icon-error'>error</mat-icon>
                                <mat-icon *ngIf='!row.error' class='icon-success'>check_circle</mat-icon>
                            </td>
                            <td class='w-15-p'>{{row.CodigoTerminal}}</td>
                            <td class='w-15-p'>{{row.FechaHoraIntentado}}</td>
                            <td class='w-15-p'>{{row.FechaHoraRespuestaRecibida}}</td>
                            <td class='w-15-p'>{{(row.RespuestaServidor | json).length > 50 ? ((row.RespuestaServidor | json) | slice:0:50) +'...' : (row.RespuestaServidor | json)}}</td>
                        </tr>
                        <!-- <tr>
                            <td class='w-15-p'>
                                <mat-icon class='icon-success'>check_circle</mat-icon>
                            </td>
                            <td class='w-15-p'>15:29</td>
                            <td class='w-15-p'>OB-5673-144</td>
                            <td class='w-15-p'>15:32</td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    
</section>