import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrincipalComponent } from './components/principal/principal.component';
import { SettingsComponent } from './components/settings/settings.component';
import { StadisticsComponent } from './components/stadistics/stadistics.component';

const routes: Routes = [
  {
    path: 'principal',
    component: PrincipalComponent,
  },
  {
    path: 'stadistics',
    component: StadisticsComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  {
    path      : '**',
    redirectTo: '/principal'
  },
  {
    path      : '',
    redirectTo: '/principal',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
