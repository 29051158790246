import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TerminalsInfoService {

  constructor(
    public httpClient: HttpClient
  ) { }

  requestTerminalsInfo(accessID: string, password: string, start_utc: string = '2021-01-01 00:00:00'){
    return this.httpClient.get(`${environment.apiURL}/get_return_messages`, {params: {
      access_id: accessID,
      password: password,
      start_utc
    }})
  }

  requestTerminalList(accessID: string, password: string){
    return this.httpClient.get(`${environment.apiURL}/get_terminals_info`, {params: {
      access_id: accessID,
      password: password
    }})
  }
}

