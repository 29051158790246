import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty, Observable, of } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GeneralService } from '../general/general.service';

@Injectable({
  providedIn: 'root'
})
export class InformeAguasSubterraneasService {

  constructor(
    private httpClient: HttpClient,
    private generalService: GeneralService
  ) { }

  sendInforme(body: string): Promise<any> {

    const headers = new HttpHeaders();
          headers.append('Content-Type', 'text/xml');
          headers.append('Accept', 'text/xml');
          
    return this.httpClient.post<any>(`${environment.apiURL}`, body, { headers: headers }).toPromise();   
  }

}
