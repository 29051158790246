import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stadistics',
  templateUrl: './stadistics.component.html',
  styleUrls: ['./stadistics.component.scss']
})
export class StadisticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
