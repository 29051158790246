import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IpcRenderer } from "electron";
import { ElectronSettingsService } from '../electron-settings/electron-settings.service';
import { GeneralService } from '../general/general.service';




@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  
  private ipc!: IpcRenderer;
  public db : any;
  constructor(
    public electronSettings: ElectronSettingsService,
    public generalService: GeneralService,
  ) {
    
    if (window.require) {
      try {
        this.ipc = window.require("electron").ipcRenderer;
      } catch (e) {
        throw e;
      }
    } else {
      console.warn("Electron IPC was not loaded");
    }

  }

  connectToDataBase(){

    const mysql =  window.require("mysql")
    const connectionData = this.electronSettings.store.getSync("orbcomm_connectionData");
    let result;

    try{

      this.db = mysql.createConnection({
        host: connectionData.host,
        user: connectionData.user,
        password: connectionData.password,
        database: connectionData.database
      });

      this.db.connect( ( err: any ) => {
        if( err ) {
          console.error(err)
          this.generalService.openSnackbar("Error al conectar a la base de datos, verifica los datos de conexion e intenta de nuevo", "red-snackbar", '', 5000)
        }
        return true
      })
      return true;
    }catch{
      return false;
    }

  }

  query(qry: any, values: Array<object> = []) {
    return new Promise((resolve, reject) => {
      if(values.length==0){
        this.db.query( qry, ( err: any, result: any ) => {
          if(err){
            reject( err );
          } else{
            resolve( result );
          }
        });
      } else{
        this.db.query( qry, values, ( err: any, result: any ) => {
          if(err){
            reject( err );
          } else{
            resolve( result );
          }
        });
      }
    })
  }

}
