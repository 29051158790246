<div class="sidebar">
    <div class="sidebar-header">
        <img class="logo" src="assets/images/proyex-logo.png" alt="Proyex">
        <div class="title">Informe de Aguas subterraneas</div>
    </div>
    
    <div class="menu">
        <ul>
            <li>
                <div (click)='this.sidebarService.active="principal"' [class.active]='this.sidebarService.active=="principal"' class="link active" routerLink='principal'>
                    <mat-icon>dashboard</mat-icon>
                    <div class='menu-text'>Principal</div>
                </div>
            </li>
            <!-- <li>
                <div (click)='activeItemMenu=2' [class.active]='activeItemMenu==2' class="link" routerLink='stadistics'>
                    <mat-icon> bar_chart</mat-icon>
                    <div class='menu-text'>Estadisticas</div>
                </div>
            </li> -->
            <li>
                <div (click)='this.sidebarService.active="settings"' [class.active]='this.sidebarService.active=="settings"' class="link" routerLink='settings'>
                    <mat-icon>settings</mat-icon>
                    <div class='menu-text'>Configuracion</div>
                </div>
            </li>
        </ul>
    </div>
</div>
