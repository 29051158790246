import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatabaseService } from 'src/app/services/database/database.service';
import { ElectronSettingsService } from 'src/app/services/electron-settings/electron-settings.service';
import { GeneralService } from 'src/app/services/general/general.service';
import { InformeAguasSubterraneasService } from 'src/app/services/informe-aguas-subterraneas/informe-aguas-subterraneas.service';
import { TerminalsInfoService } from 'src/app/services/terminals-info/terminals-info.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {

  informeCicle: boolean = false
  terminalsList: any;
  terminalsListDB: any;
  terminalsListUltimate: any;
  monitoreoActividad: string = '';
  informeActividad: any = [];
  timeToNextClicle: number = 0;
  terminalStep: number = 0;
  intervalV!: any;
  timer!: FormControl;

  credentials!: {accessID: string, password: string};

  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;

  constructor(
    public databaseService: DatabaseService,
    public electronSettings: ElectronSettingsService,
    public informeAguasSubterraneasService: InformeAguasSubterraneasService,
    public teminalsInfo: TerminalsInfoService,
    public generalService: GeneralService,
  ) { }

  async ngOnInit(){


    this.timer = new FormControl(this.electronSettings.store.getSync('orbcomm_timer').timer || 60);

    this.timer.valueChanges.subscribe(async (value: number) => {
      this.electronSettings.store.set('orbcomm_timer', {timer: value});
    });


    if(!this.databaseService.connectToDataBase()){
      return;
    }

    let qry = `SELECT COUNT(*) FROM mobile_messages_alt`;
    let result: any = await this.databaseService.query(qry);
    
    console.log(result);

    this.credentials = this.electronSettings.store.getSync('orbcomm_credentials');

    this.refreshTerminalListWithRemoteData();

  }

  async refreshTerminalListWithRemoteData(){
    await this.teminalsInfo.requestTerminalList(this.credentials.accessID, this.credentials.password).toPromise()
    .then(async (response: any) =>{
        this.terminalsList = response.Terminals

        let qry = `SELECT mobile_id, MAX(datetime) as datetime FROM mobile_messages_alt GROUP BY mobile_id ORDER BY datetime ASC`;
        let result: any = await this.databaseService.query(qry);

        this.terminalsListDB=result


        this.terminalsListUltimate = this.terminalsList.filter((terminal: any) =>{
            return !this.terminalsListDB.find((terminalDB: any) => terminalDB.mobile_id === terminal.PrimeID)
        });

        this.terminalsListUltimate=[
                                    ...this.terminalsListUltimate.map((terminal: any) => {return {PrimeID: terminal.PrimeID, datetime: null}}), 
                                    ...this.terminalsListDB.map((terminal: any) => {return {PrimeID: terminal.mobile_id, datetime: terminal.datetime}})
                                  ]
                                  
    });
  }


  async refreshTerminalList(){

        let qry = `SELECT mobile_id, MAX(datetime) as datetime FROM mobile_messages_alt GROUP BY mobile_id ORDER BY datetime ASC`;
        let result: any = await this.databaseService.query(qry);

        this.terminalsListDB=result


        this.terminalsListUltimate = this.terminalsList.filter((terminal: any) =>{
            return !this.terminalsListDB.find((terminalDB: any) => terminalDB.mobile_id === terminal.PrimeID)
        });

        this.terminalsListUltimate=[
                                    ...this.terminalsListUltimate.map((terminal: any) => {return {PrimeID: terminal.PrimeID, datetime: null}}), 
                                    ...this.terminalsListDB.map((terminal: any) => {return {PrimeID: terminal.mobile_id, datetime: terminal.datetime}})
                                  ]
  }


  async startInformeCicle(): Promise<any>{

    if(!this.databaseService.connectToDataBase()){
      return;
    }

    while(!this.terminalsListUltimate){
      await this.refreshTerminalListWithRemoteData();
    }

    this.informeCicle=true;


    
    while(this.informeCicle){
      clearInterval(this.intervalV)

      this.monitoreoActividad = `Solicitando datos del terminales`

      

      const datetime = this.terminalsListUltimate[this.terminalStep].datetime ? new Date(this.transformDate(this.terminalsListUltimate[this.terminalStep].datetime)) : new Date('2021-01-01 00:00:00')

      datetime.setMinutes( datetime.getMinutes() + 1 );

          const response: any = await this.teminalsInfo.requestTerminalsInfo(this.credentials.accessID, this.credentials.password, this.transformDate(datetime)).toPromise() 

            if(response.ErrorID!=0){

              this.informeActividad.push({
                'error': true,
                'FechaHoraIntentado': this.transformDate(new Date()),
                'CodigoTerminal': this.terminalsListUltimate[this.terminalStep].primeID,
                'FechaHoraRespuestaRecibida': this.transformDate(new Date()),
                'RespuestaServidor': response
              });

              this.monitoreoActividad='';
              if(this.informeCicle){
                await this.interval()
              }
              this.informeActividad=this.informeActividad.slice(Math.max(this.informeActividad.length - 100, 0))
              this.monitoreoActividad='';

              continue
            }

              let messages: any = {}
              response.Messages.map((message: any) =>{

                if(!messages[message.MobileID]){
                  messages[message.MobileID]=[];
                }

                if(message.Payload){
                
                  const fields=message.Payload.Fields
                  
                  
                  if(fields[6] && message.SIN==224){

                    let datetime=new Date(this.getCompleteDate(fields));

                    const data=fields[6].Elements.map((field: any)=>{
                        messages[message.MobileID].push([
                          message.ID,
                          message.MobileID,
                          message.MobileOwnerID,
                          message.MessageUTC,
                          message.ReceiveUTC,
                          this.transformDate(datetime),
                          message.Payload.Name,
                          field.Fields[0].Value / 1000,
                          field.Fields[1].Value / 1000,
                          field.Fields[2].Value / 1000,
                          field.Fields[3].Value / 1000,
                          field.Fields[4].Value / 1000,
                          field.Fields[5].Value / 1000,
                          field.Fields[6].Value / 1000
                        ])
                      datetime.setMinutes( datetime.getMinutes() + 1 );
                    });
                  }

                }
              })
              
              this.monitoreoActividad = `Registrando informacion recibida`
              
              console.log(messages)
              for(const message of Object.entries(messages)){

                if((message[1] as Array<any>).length==0){
                  continue
                }
                
                let qry = `SELECT * FROM mobile_messages_alt 
                            WHERE datetime IN (?)
                            AND mobile_id='${message[0]}'`;

                let resultQRY: any = await this.databaseService.query(qry, [(message[1] as Array<any>).map((item:any) => item[5])]);
                
                message[1]=(message[1] as Array<any>).filter(item => {
                  return !resultQRY.find((item2: any) => item[5]==this.transformDate(item2.datetime))
                })

                if((message[1] as Array<any>).length > 0) {

                  
                  qry = `INSERT INTO mobile_messages_alt
                  (message_id, mobile_id, mobile_owner_id, message_utc, receive_utc, datetime, payload_name, pressure, temperature, pressure_2, temperature_2, ph, orp, conductivity) 
                  VALUES ?`;

                  resultQRY = await this.databaseService.query(qry, [message[1] as Array<any>]);
                  
                  
                }
                
                

                this.informeActividad.push({
                  'error': false,
                  'FechaHoraIntentado': this.transformDate(new Date()),
                  'CodigoTerminal': this.terminalsListUltimate[this.terminalStep].datetime,
                  'FechaHoraRespuestaRecibida': this.transformDate(new Date()),
                  'RespuestaServidor': ''
                });


              }


        this.terminalStep++;

        this.monitoreoActividad='';
        if(this.informeCicle){
          if(this.terminalStep==this.terminalsListUltimate.length){
            this.refreshTerminalListWithRemoteData();
          }

          await this.interval()
        }

        this.informeActividad=this.informeActividad.slice(Math.max(this.informeActividad.length - 100, 0))
        this.monitoreoActividad='';


        if(this.terminalStep==this.terminalsListUltimate.length){
          this.terminalStep=0;
        }


      }
    

  }

  consultarTerminal(){
    
  }

  stopInformeCicle(){
    this.informeCicle=false;
    clearInterval(this.intervalV)
    this.monitoreoActividad='';
  }

  interval(){
    this.timeToNextClicle=this.timer.value;
    this.monitoreoActividad = `Nuevo ciclo en ${this.timeToNextClicle} segundos`
    return new Promise((resolve,reject) => {
      
      this.intervalV = setInterval(() => {
          this.timeToNextClicle=this.timeToNextClicle-1
          this.monitoreoActividad = `Nuevo ciclo en ${this.timeToNextClicle} segundos`

          if(this.timer.value/2==this.timeToNextClicle){
            console.log("Se hizo ultimate!");
            this.refreshTerminalList();
          }

        1}, 1000)

      setTimeout(() => {
        clearInterval(this.intervalV)
        resolve(true)
      }, this.timer.value*1000)
    }
    )
  }


  scrollToBottom(): void {
      try {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch(err) { }                 
  }

  transformDate(date: Date): string{
    return date.getFullYear() + '-' +("0" + (date.getMonth() + 1)).slice(-2) + '-' + (date.getDate().toString().length == 1 ? "0"+date.getDate() : date.getDate()) + " "+("0" +date.getHours()).slice(-2) + ':' +("0" + date.getMinutes()).slice(-2) + ':' + ("0" +date.getSeconds()).slice(-2)
  }

  getCompleteDate(field: any){
    return `20${field[0].Value}-
              ${field[1].Value.length==1?"0"+field[1].Value:field[1].Value}-
              ${field[2].Value.length==1?"0"+field[2].Value:field[2].Value} 
              ${field[3].Value.length==1?"0"+field[3].Value:field[3].Value}:
              ${field[4].Value.length==1?"0"+field[4].Value:field[4].Value}:
              00`
  }

}
