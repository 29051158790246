import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { MatTableDataSource } from '@angular/material/table';
import { ElectronSettingsService } from 'src/app/services/electron-settings/electron-settings.service';
import { GeneralService } from 'src/app/services/general/general.service';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  connectionFormGroup!: FormGroup;
  credentialsFormGroup!: FormGroup;
  action: 'create' | 'edit' = 'create';
  terminalCodeEditing: string = '';

  displayedColumns = ['position', 'name', 'weight', 'symbol', 'edit', 'delete'];
  dataSource = new MatTableDataSource<any>();



  constructor(
    public formBuilder: FormBuilder,
    public electronSettings: ElectronSettingsService,
    public generalService: GeneralService,
    private changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    
    this.connectionFormGroup = this.formBuilder.group({
      host: [],
      database: [],
      user: [],
      password: [],
    })

    this.credentialsFormGroup = this.formBuilder.group({
      accessID: [],
      password: [],
    });

    this.getTerminalsList();

    this.verifyConnectionData();
  }

  getTerminalsList(){
    const terminalList = this.electronSettings.store.getSync('orbcomm_terminalsList');

    if(Object.keys(terminalList).length === 0){
      this.dataSource.data = [];
    } else {
      this.dataSource.data = terminalList;
    }

    // console.log(terminalList)
  } 

  verifyConnectionData(){


    if(this.electronSettings.store.getSync('orbcomm_connectionData')){
      const connectionData = this.electronSettings.store.getSync('orbcomm_connectionData')
      this.connectionFormGroup.get("host")?.setValue(connectionData.host);
      this.connectionFormGroup.get("database")?.setValue(connectionData.database);
      this.connectionFormGroup.get("user")?.setValue(connectionData.user);
      this.connectionFormGroup.get("password")?.setValue(connectionData.password);
  }

  if(this.electronSettings.store.getSync('orbcomm_credentials')){
    const credentialData = this.electronSettings.store.getSync('orbcomm_credentials')
    // console.log(credentialData)
    this.credentialsFormGroup.get("accessID")?.setValue(credentialData.accessID);
    this.credentialsFormGroup.get("password")?.setValue(credentialData.password);
}
  }

  setConnectionData(){

    this.electronSettings.store.set('orbcomm_connectionData', {
      host:     this.connectionFormGroup.get("host")?.value,
      database: this.connectionFormGroup.get("database")?.value,
      user:     this.connectionFormGroup.get("user")?.value,
      password: this.connectionFormGroup.get("password")?.value
    });

    this.generalService.openSnackbar('Datos de conexion guardados exitosamente');

  }

  debugFunction(){
    const connectionData = this.electronSettings.store.getSync('orbcomm_terminalsList')
    // console.log(connectionData)
  }

  updateCredential(){

    this.electronSettings.store.set('orbcomm_credentials', {
      accessID: this.credentialsFormGroup.get("accessID")?.value,
      password: this.credentialsFormGroup.get("password")?.value
    });

    this.generalService.openSnackbar('Datos de conexion guardados exitosamente');
  }

}
