import { Component, OnInit } from '@angular/core';
import { DatabaseService } from './services/database/database.service';
import { SidebarService } from './services/sidebar/sidebar.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'test';

  constructor(
    // public databaseService: DatabaseService,
    public sidebarService: SidebarService
    ){

  }

  ngOnInit() {
    // this.testQuery();
  }

  // testQuery() {

  //   let qry = "SELECT * FROM registrosdga LIMIT 1000";
  //   this.databaseService.query( qry, ( err: any, result: any ) => {
  //     if( err ) {

  //       console.log('err', err);
  //     } else {

  //       console.log('result', result);
  //     }
  //   } );
  // }

}
