import { Injectable, OnInit } from '@angular/core';
import { IpcRenderer } from "electron";

@Injectable({
  providedIn: 'root'
})
export class ElectronSettingsService implements OnInit {

  public store: any;
  private ipc!: IpcRenderer;

  constructor(
    ) {

      if (window.require) {
        try {
          this.ipc = window.require("electron").ipcRenderer;
        } catch (e) {
          throw e;
        }
      } else {
        console.warn("Electron IPC was not loaded");
      }
      
      this.store = window.require('electron-json-storage');
      const os = window.require('os');
      
      this.store.setDataPath(os.tmpdir());
      const dataPath = this.store.getDataPath();

      // console.log(dataPath);


    }

  ngOnInit() {
    
  }
}



